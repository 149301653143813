'use client';

import { useEffect, useState, useTransition } from 'react';
import { useFormState } from 'react-dom';
import { getCsrfToken } from 'next-auth/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSearchParams } from 'next/navigation';

import { authenticate, type FormData } from './_actions/login';
import { LoginFormProvider } from './_components/LoginFormProvider';

export default function LoginLayout({
  children,
}: React.PropsWithChildren) {
  const form = useForm<FormData>({
    reValidateMode: 'onChange',
  });

  const searchParams = useSearchParams();
  const [pending, startTransition] = useTransition();
  const [error, formAction] = useFormState(authenticate, null);
  const [token, setToken] = useState<string>('');

  /**
   * @see https://github.com/orgs/react-hook-form/discussions/10757#discussioncomment-6672403
   */
  const onSubmit: SubmitHandler<FormData> = data => {
    startTransition(() => {
      return formAction({
        ...data,
        redirectTo: searchParams.get('redirectTo') ?? '/',
      });
    });
  };

  useEffect(() => {
    getCsrfToken().then(token => setToken(token));
  }, []);

  return (
    <LoginFormProvider form={form} pending={pending} error={error}>
      <div className="text-gray-900">
        <form onSubmit={form.handleSubmit(onSubmit)} noValidate={true} className="text-inherit">
          <input type="hidden" name="csrfToken" defaultValue={token} />

          {children}
        </form>
      </div>
    </LoginFormProvider>
  );
}
